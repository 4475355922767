import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MapboxAutocomplete from 'containers/mapbox/autocomplete';
import CoordinatesModel from 'models/coordinates';
import Insights from '@parkwhiz-js/insights-sdk';
import * as AppContext from 'lib/app-context';
import Brand from 'models/brand';
import get from 'lodash/get';

import { pageProps } from 'lib/analytics/page-properties';
import { ERROR_APP } from 'lib/app-names';
import { PAGEVIEW_EVENT } from 'lib/analytics/events';
import { imageCdnAsset } from 'lib/common/url-helpers';

const propTypes = {
  app: PropTypes.string.isRequired,
  addMessageAndScrollToTop: PropTypes.func.isRequired,
  geoIPLocation: PropTypes.instanceOf(CoordinatesModel).isRequired,
  appContext: PropTypes.string,
  trackEvent: PropTypes.func.isRequired,
  insights: PropTypes.instanceOf(Insights).isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  searchText: PropTypes.string,
};

const defaultProps = {
  appContext: AppContext.DESKTOP,
  searchText: '',
};

class Error404 extends Component {
  componentDidMount() {
    const pageProperties = pageProps({ app: ERROR_APP });
    this.props.trackEvent({
      ...PAGEVIEW_EVENT,
      ...pageProperties,
      name: '404',
      properties: {
        searchText: this.props.searchText,
        referrer: get(document, 'referrer', ''),
      },
    });
  }

  render() {
    const { app, addMessageAndScrollToTop, geoIPLocation, appContext, trackEvent, insights, brand } = this.props;
    const buttonBackgroundColorClassName = brand.isBestParking ? 'btn-bp' : 'btn-watermelon';
    return (
      <div>
        <div className="row margin-top-sm-40 margin-bottom-sm-10 margin-top-xs-20 margin-bottom-xs-10 text-align-center">
          <div className="col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1 padding-top-5 padding-bottom-5">
            <img src={imageCdnAsset('/images/error/404@2x.png')} alt="Error 404" />
          </div>
        </div>
        <div className="row margin-bottom-sm-40 margin-bottom-xs-20 text-align-center">
          <div className="col-sm-8 col-sm-offset-2 col-xs-10 col-xs-offset-1 text-size-xs-14 text-size-sm-16 text-weight-book">
            Sorry, no parking here. Let&#39;s get you going in the right direction again. Head back to our home page, or search for parking below.
          </div>
        </div>
        <div className="row text-align-center margin-bottom-xs-60">
          <div className="col-sm-8 col-sm-offset-2 col-xs-10 col-xs-offset-1">
            <div className="input-group cta-hero-input with-map-pin-icon">
              <MapboxAutocomplete
                className="gplaces-autocomplete "
                buttonClassName={`btn ${buttonBackgroundColorClassName}`}
                buttonText="FIND PARKING"
                dropdownClassName="location-suggestion-bar-homepage"
                inputClassName="form-control focusField gplaces-input-dropdown"
                addMessageAndScrollToTop={addMessageAndScrollToTop}
                geoIPLocation={geoIPLocation}
                placeholder={appContext === AppContext.MOBILE ? 'Search Address or Place' : 'Search Address, Place or Event'}
                app={app}
                appContext={appContext}
                trackEvent={trackEvent}
                insights={insights}
                id="404-autocomplete"
                fullscreen
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Error404.propTypes = propTypes;
Error404.defaultProps = defaultProps;
export default Error404;
