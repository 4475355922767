import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Error404 from 'components/common/error/404.jsx';

import CoordinatesModel from 'models/coordinates';
import Insights from '@parkwhiz-js/insights-sdk';
import * as AppContext from 'lib/app-context';

import addMessageAndScrollToTopCreator from 'action-creators/messaging/add-message-and-scroll-to-top';
import trackEventCreator from 'action-creators/analytics/track-event';

import Brand from 'models/brand';

const propTypes = {
  app: PropTypes.string.isRequired,
  addMessageAndScrollToTop: PropTypes.func.isRequired,
  geoIPLocation: PropTypes.instanceOf(CoordinatesModel).isRequired,
  appContext: PropTypes.string,
  trackEvent: PropTypes.func.isRequired,
  insights: PropTypes.instanceOf(Insights).isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  searchText: PropTypes.string,
};

const defaultProps = {
  appContext: AppContext.DESKTOP,
  searchText: '',
};

const ErrorPage = ({ app, addMessageAndScrollToTop, geoIPLocation, appContext, trackEvent, insights, brand, searchText }) => (
  <Error404
    app={app}
    addMessageAndScrollToTop={addMessageAndScrollToTop}
    geoIPLocation={geoIPLocation}
    appContext={appContext}
    trackEvent={trackEvent}
    insights={insights}
    brand={brand}
    searchText={searchText}
  />
);

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    addMessageAndScrollToTop: addMessageAndScrollToTopCreator,
    trackEvent: trackEventCreator,
  }, dispatch)
);

const mapStateToProps = (state) => {
  const { geoIPLocation, searchText } = state.search;
  const {
    name: app,
    appContext,
  } = state.app;

  const { brand } = state.brand;

  const { insights } = state.analytics;

  return {
    geoIPLocation,
    app,
    appContext,
    brand,
    searchText,
    insights,
  };
};

ErrorPage.propTypes = propTypes;
ErrorPage.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
